/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsbState } from './usb.reducer';
import { selectAudioSettings } from '../../settings/+state/settings.selectors';

export const USB_FEATURE = 'usb';
export const selectUsbState = createFeatureSelector<UsbState>(USB_FEATURE);
export const selectUsbEffectInitialized = createSelector(selectUsbState, (state) => state.effectsInitialized);

export const selectSamAttached = createSelector(selectUsbState, (state) => false);
export const selectRtiAttached = createSelector(selectUsbState, (state) => false);

export const selectUsbAttached = createSelector(selectSamAttached, selectRtiAttached, (samAttached, rtiAttached) => samAttached || rtiAttached);

export const selectConnectedUsbCallAudioInputs = createSelector(selectUsbState, (state) => state.inputs.filter((input) => input.connected));

export const selectConnectedUsbCallAudioOutputs = createSelector(selectUsbState, (state) => state.outputs.filter((input) => input.connected));

export const selectMuteOnAnswerUsbInputs = createSelector(selectConnectedUsbCallAudioInputs, (inputs) => inputs.filter((input) => input.muteOnAnswer));

export const selectUsbAlertOutputToDevice = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.alertToDevice !== undefined ? audioSettings.alertToDevice : state.alertToDevice);

export const selectUsbAlertOutputToHeadsets = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.alertToHeadsets !== undefined ? audioSettings.alertToHeadsets : state.alertToHeadsets);

export const selectUsbIrrOutputToDevice = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.irrToDevice !== undefined ? audioSettings.irrToDevice : state.irrToDevice);

export const selectUsbIrrOutputToHeadsets = createSelector(selectUsbState, selectAudioSettings, (state, audioSettings) => audioSettings.irrToHeadsets !== undefined ? audioSettings.irrToHeadsets : state.irrToHeadsets);
